
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    
}

/* App.css */
#root {
  text-align: center;
  margin: 1.2rem;
}

nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}


.nav-container{
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 max-height: 74px;
 height: 74px;
 background-color: white;
}

.nav-border-bottom {
  padding-bottom:5px; 
  border-bottom:1px solid grey;
}

.logo-left {
 height:  74px;
 max-height: 74px;
 width: 74px;
 max-width: 74px;
}

.userPic{
  height: 24px;
  width: 24px;
  border-radius: 12px;
}

.PageName {
  font-size: 1.5rem;
  display: flex;
   align-items: flex-end;
}

.logoutText {
  font-size: 1.5rem;

}

.TitleArea {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 8.4vh;
}

.PageTitle {
  display: flex;
  color: aquamarine;
  font-size: 2rem;
  justify-content: center;
}

/** Sidebar***/
.SideBarAndContent {
  display: flex;
  flex-direction: row;
  background-color: white;
}
.main-side-container {
  background-color: white;
 
} 

/**************/
/* Main content */

.mainContent {
  padding: 10px;
  color: aquamarine;
  height:100vh;
  width: 100vw;
  
}

.bib-break {
  flex-basis: 100%;
  height: 0;
}

.bib-container {
  display: flex;
  flex-wrap: wrap;
}

.bib-container-child {
  flex: 1 0 21%; /* explanation below */
  margin: 5px;
}



.SideBarAndContentViewElement {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
}

.SideBarAndContentViewQuotes {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
  
}

.SideBarAndContentCreateBib {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.SideBarAndContentCreateElement {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.quote-container {
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-between;
}

.quote-container-child {
  padding: 5px;
}

.element-container{
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.element-container-child {
  padding: 5px;
}

.search-container{
  display: flex;
  flex-direction: row;
  background-color: white;
  justify-content: space-evenly;
}

.text-container-child {
  padding: 5px;
}

.page {
  height: 100%;
  background-color: white;
}


.sideBarIcon {
  width: 60px;
  height: 60px;
}


/********/
/* create bib form */


.CreateBibFormBox {
  display: flex;
  flex-direction: column;
  
}

.sidebar-nav {
  padding-left: 200px;
}


/**********?
/ text search
**/

.text-search-box-and-result{
  display: flex;
  flex-direction: column;
}

.amplify-highlightmatch__highlighted {
  color: red
}


.createElementForm {
  display: flex;
  flex-direction: column;
  padding-left: 250px;
  
}


